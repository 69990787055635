<route lang="yaml">
meta:
  requiresAuth: true
</route>

<template>
  <div>
    <TopBar
      :title="title"
      :sub-title="subTitle"
      :is-manager="isManager"
      :report-title="reportTitle"
      @download-pdf="downloadPDF"
      @download-csv="downloadCSV"
      @download-xls="downloadPivot"
    >
    </TopBar>
<!--    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">-->
<!--      <h2 class="text-lg font-medium mr-auto">{{ title }}<span class="text-sm">{{ subTitle }}</span></h2>-->
<!--      <div class="flex flex-col sm:flex-row sm:items-end xl:items-end pt-5">-->
<!--        <form-->
<!--          id="tabulator-html-filter-form2"-->
<!--          class="xl:flex sm:mr-auto float-right"-->
<!--        >-->
<!--          <div class="flex mt-5 sm:mt-0">-->
<!--            <button-->
<!--              id="cancel-filter"-->
<!--              class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"-->
<!--              type="button"-->
<!--              @click="cancelFilter()"-->
<!--            >-->
<!--              <RotateCcwIcon class="w-4 h-4 mr-2" /> Zruš filter-->
<!--            </button>-->
<!--            <button-->
<!--              id="pdf-print"-->
<!--              class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"-->
<!--              type="button"-->
<!--              @click="downloadPDF()"-->
<!--            >-->
<!--              <BookOpenIcon class="w-4 h-4 mr-2" /> Stiahni PDF-->
<!--            </button>-->
<!--            <button-->
<!--              id="csv-print"-->
<!--              class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"-->
<!--              type="button"-->
<!--              @click="downloadCSV()"-->
<!--            >-->
<!--              <ListIcon class="w-4 h-4 mr-2" /> Stiahni CSV-->
<!--            </button>-->
<!--            <button-->
<!--              v-if="!isManager"-->
<!--              id="xls-print"-->
<!--              class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"-->
<!--              type="button"-->
<!--              @click="downloadPivot()"-->
<!--            >-->
<!--              <ServerIcon class="w-4 h-4 mr-2" /> Stiahni Pivot-->
<!--            </button>-->
<!--          </div>-->
<!--        </form>-->
<!--      </div>-->
<!--    </div>-->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <form id="tabulator-html-filter-form" class="flex flex-col sm:flex-row sm:items-end xl:items-center">
          <div class="sm:flex items-center sm:mr-4">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Rok</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-year"
              v-model="selectedYear"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <option
                v-for="option in optionsYears"
                :key="option.key"
                :value="option.text"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Mesiac</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-month"
              v-model="selectedMonth"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <!--              <option-->
              <!--                v-for="option in optionsMonths"-->
              <!--                :key="option.key"-->
              <!--                :value="option.key"-->
              <!--              >-->
              <!--                {{ option.text }}-->
              <!--              </option>-->
              <option value="1" selected>Január</option>
              <option value="2">Február</option>
              <option value="3">Marec</option>
              <option value="4">Apríl</option>
              <option value="5">Máj</option>
              <option value="6">Jún</option>
              <option value="7">Júl</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">Október</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 xl:ml-auto mt-2 xl:mt-0">
            <button
                id="cancel-filter"
                class="ml-3 btn box  btn-outline-dark calncel-filter-button"
                type="button"
                @click="cancelFilter"
              >
                <RotateCcwIcon class="hidden sm:block w-4 h-4 mr-2" /> Zruš filter
              </button>
            </div>
        </form>
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-end pt-5">
        <form id="tabulator-html-filter-form1" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Stredisko</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-stredisko"
              v-model="strediskoSelect"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <option value="" disabled selected hidden>Stredisko</option>
              <option
                v-for="option in optionStredisko"
                :key="option.key"
                :value="option.text"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Zákazka</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-zakazka"
              v-model="zakazkaSelect"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <option value="" disabled selected hidden>Zákazka</option>
              <option
                v-for="option in optionZakazka"
                :key="option.key"
                :value="option.text"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div v-if="showGP1" class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Grupačné pole 1</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-gp1"
              v-model="gP1Select"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <option value="" disabled selected hidden>Grupačné pole 1</option>
              <option
                v-for="option in optionGP1"
                :key="option.key"
                :value="option.text"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div v-if="showGP2" class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Grupačné pole 2</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-gp2"
              v-model="gP2Select"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <option value="" disabled selected hidden>Grupačné pole 2</option>
              <option
                v-for="option in optionGP2"
                :key="option.key"
                :value="option.text"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div v-if="showGP3" class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Grupačné pole 3</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-gp3"
              v-model="gP3Select"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <option value="" disabled selected hidden>Grupačné pole 3</option>
              <option
                v-for="option in optionGP3"
                :key="option.key"
                :value="option.text"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <div id="failed-csv-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Súbor</div>
        <div class="text-gray-600 mt-1">
          Nepodarilo sa vygenerovat pozadovaný súbor
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  watch
} from "vue";
import xlsx from "xlsx";
import feather from "feather-icons";
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import TopBar from "@/components/top-bar/Main";
import getApi from "@/main";
import { useStore } from "@/store";
import fileDownload from "js-file-download";
import { reformatPercentage } from "../../main";
import Toastify from "toastify-js";

export default defineComponent({
  components: {
    TopBar
  },
  props: {
    companyName: {
      type: String,
      default: "",
      required: true
    },
    companyIndex: {
      type: String,
      default: "",
      required: true
    },
    companyId: {
      type: Number,
      default: 0,
      required: true
    },
    startMonth: {
      type: Number,
      default: 1,
      required: true
    },
    endMonth: {
      type: Number,
      default: 12,
      required: true
    },
    startYear: {
      type: Number,
      default: 2021,
      required: true
    },
    endYear: {
      type: Number,
      default: 2021,
      required: true
    },
    currency: {
      type: String,
      default: "",
      required: true
    },
    multiply: {
      type: String,
      default: "",
      required: true
    }
  },
  setup(props) {
    const curr = ref('');
    const breadcrumb = ref('Súvaha vývoj');
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "name",
      type: "like",
      value: ""
    });
    const loadedDataStredisko = ref([]);
    const strediskoSelect = ref("");
    const loadedDataZakazka = ref([]);
    const zakazkaSelect = ref("");
    const loadedDataGP1 = ref([]);
    const gP1Select = ref("");
    const loadedDataGP2 = ref([]);
    const gP2Select = ref("");
    const loadedDataGP3 = ref([]);
    const gP3Select = ref("");
    const internalInstance = getCurrentInstance();
    const axios = internalInstance.appContext.config.globalProperties.axios;
    const datas = ref([]);
    const headers = ref([]);
    const previousYear = ref(true);
    const forecast = ref(true);
    const loadedData = ref();
    const selectedMonth = ref("1");
    const selectedYear = ref("2021");
    const multiply = ref("");
    const store = useStore();
    const isDesktopResolution = Boolean(window.innerWidth > 768);

    const isManager = computed(() => {
      return store.getters["user/isManager"]
    })

    const downloadPivot = () => {
      const store = useStore();
      const headers = {
        Authorization: "Bearer " + store.getters["user/token"]
      };
      axios
        .get(getApi() + `/company/pivot/${props.companyId}`, {
          responseType: "blob",
          headers: headers
        })
        .then(response => {
          if (response.data.size > 0) {
            fileDownload(response.data, "kontingencna-tabulka.xlsx");
          } else {
            Toastify({
              node: cash("#failed-csv-content")
                .clone()
                .removeClass("hidden")[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
          }
        }).catch(error => {
        csvError.value = error.message;
        Toastify({
          node: cash("#failed-csv-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        selectable: false,
        footerElement: "<p></p>",
        maxHeight:"100%",
        columnHeaderVertAlign: "bottom",
        headerHozAlign: "right",
        placeholder: "Žiadny záznam",
        // Row formatter
        rowFormatter: function(row) {
          row.getElement().style.height = "15px";
          row.getElement().style.minHeight = "15px";
          row.getElement().style.fontFamily = "Arial, sans-serif";
          if (row.getData().style === "METRIC") {
            row.getElement().style.backgroundColor = "#B2E29C";
            row.getElement().style.fontWeight = "bold";
            row.getElement().style.fontSize = "14px";
            row.getElement().style.height = "40px";
          }
          if (row.getData().style === "PERCENTAGE") {
            row.getElement().style.fontStyle = "italic";
            row.getElement().style.fontWeight = "bold";
            row.getElement().style.fontSize = "11px";
          }
          if (row.getData().style === "GROUP") {
            row.getElement().style.fontWeight = "bold";
            row.getElement().style.fontSize = "14px";
            row.getElement().style.height = "30px";
          }
        },
        columns: headers.value,
        renderComplete() {
          feather.replace({
            "stroke-width": 1.5
          });
        },
        data: datas.value,
      });
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5
        });
      });
    };

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "name";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };

    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };

    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products"
      });
    };

    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true
      });
    };

    // Print
    const onPrint = () => {
      tabulator.value.print();
    };

    const getRequest = computed(() => {
      const data = {
        stredisko: strediskoSelect.value === "" ? null : strediskoSelect.value,
        zakazka: zakazkaSelect.value === "" ? null : zakazkaSelect.value,
        grupacnePole1: gP1Select.value === "" ? null : gP1Select.value,
        grupacnePole2: gP2Select.value === "" ? null : gP2Select.value,
        grupacnePole3: gP3Select.value === "" ? null : gP3Select.value
      };

      const visualData = {
        monthly: true,
        prev: previousYear.value,
        fcst: forecast.value
      };

      const shares = [];

      return {
        companyId: props.companyId,
        year: selectedYear.value,
        month: selectedMonth.value,
        filter: data,
        visual: visualData,
        shares: shares
      };
    });

    const downloadPDF = () => {
      const store = useStore();
      axios
        .put(getApi() + `/report/suvaha/pdf/`, getRequest.value,
          {
            responseType: 'blob',
            Authorization: `Bearer ${store.getters["user/token"]}`
          })
        .then(pdf => {
          const blob = new Blob([pdf.data], { type: "application/pdf" });
          const blobUrl = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = blobUrl;
          link.setAttribute('download', props.companyName + "-suvaha.pdf");
          link.click();
          URL.revokeObjectURL(blobUrl);
          link.remove();
        }).catch(error => {
        csvError.value = error.message;
        Toastify({
          node: cash("#failed-csv-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const csvError = ref()

    const downloadCSV = () => {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`,
          responseType: "blob"
        }
      };
      axios
        .put(getApi() + `/report/suvaha/csv/`, getRequest.value, config)
        .then(response => {
          const blob = new Blob([response.data], { type: "text/plain" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = props.companyName + "-suvaha.csv";
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(error => {
        csvError.value = error.message;
        Toastify({
          node: cash("#failed-csv-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      });
    };

    const optionStredisko = computed(() => {
      return loadedDataStredisko.value;
    });
    const optionZakazka = computed(() => {
      return loadedDataZakazka.value;
    });
    const optionGP1 = computed(() => {
      return loadedDataGP1.value;
    });
    const optionGP2 = computed(() => {
      return loadedDataGP2.value;
    });
    const optionGP3 = computed(() => {
      return loadedDataGP3.value;
    });

    const optionsYears = computed(() => {
      const yearArray = [];
      const firstYear = parseInt(props.startYear);
      let year = parseInt(props.endYear);
      while (firstYear < year) {
        yearArray.push({ key: year, text: String(year) });
        year--;
      }
      return yearArray;
    });

    const getFilters = () => {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`
        }
      };
      axios
        .get(
          getApi() + "/company/resource/" + props.companyIndex + "/filter.json",
          config
        )
        .then(response => {
          loadedDataGP1.value.push({key: "", text: ""})
          loadedDataGP2.value.push({key: "", text: ""})
          loadedDataGP3.value.push({key: "", text: ""})
          for (let item of Object.keys(response.data.stredisko.map)) {
            loadedDataStredisko.value.push({ key: item, text: item });
          }
          loadedDataStredisko.value.sort((a, b) => (a.key > b.key) ? 1 : (b.key > a.key) ? -1 : 0);
          for (let item of Object.keys(response.data.zakazka.map)) {
            loadedDataZakazka.value.push({ key: item, text: item });
          }
          loadedDataZakazka.value.sort((a, b) => (a.key > b.key) ? 1 : (b.key > a.key) ? -1 : 0);
          for (let item of Object.keys(response.data.grupacnePole1.map)) {
            if (item !== "") {
              loadedDataGP1.value.push({ key: item, text: item });
            }
          }
          loadedDataGP1.value.sort((a, b) => (a.key > b.key) ? 1 : (b.key > a.key) ? -1 : 0);
          for (let item of Object.keys(response.data.grupacnePole2.map)) {
            if (item !== "") {
              loadedDataGP2.value.push({ key: item, text: item });
            }
          }
          loadedDataGP2.value.sort((a, b) => (a.key > b.key) ? 1 : (b.key > a.key) ? -1 : 0);
          for (let item of Object.keys(response.data.grupacnePole3.map)) {
            if (item !== "") {
              loadedDataGP3.value.push({ key: item, text: item });
            }
          }
          loadedDataGP3.value.sort((a, b) => (a.key > b.key) ? 1 : (b.key > a.key) ? -1 : 0);loadedDataGP3.value.sort((a, b) => a.text.localeCompare(b));
        });
    };

    const showGP1 = computed(() => {
      return loadedDataGP1.value.length > 1;
    });
    const showGP2 = computed(() => {
      return loadedDataGP2.value.length > 1;
    });
    const showGP3 = computed(() => {
      return loadedDataGP3.value.length > 1;
    });
    const lastYearTable = ref();
    const updateTabulator = (previousYear, data) => {
      datas.value = [];
      for (let item of data.rows) {
        let name = item.name;
        if (item.style === "PERCENTAGE") {
          name = "<span style='padding-left:9px'>" + name;
        }
        if (item.style === "ITEM") {
          name = "<span style='padding-left:18px'>" + name;
        }
        let dataRow = {
          name: name,
          style: item.style
        };
        let index = 0;
        for (let subItem of item.values) {
          let field = "values[" + index + "]";
          dataRow[field] = subItem;
          index++;
        }
        datas.value.push(dataRow);
      }
      headers.value = [];
      // First column formatting
      headers.value.push({
        headerHozAlign: "left",
        title: titleExtend,
        width: 320,
        responsive: 0,
        field: "name",
        vertAlign: "middle",
        print: false,
        download: false,
        hozAlign: "left",
        frozen: isDesktopResolution,
        resizable: false,
        headerSort: false,
        formatter: function(cell) {
           cell.getElement().style.maxHeight = "16px";
          cell.getElement().style.height = "16px";
          if (cell.getRow().getData().style !== "METRIC") {
            cell.getElement().style.backgroundColor = "#E2EFDA";
            if (cell.getRow().getData().style === "GROUP") {
                cell.getElement().style.maxHeight = "30px";
                cell.getElement().style.height = "30px";
                cell.getElement().style.paddingTop = "15px";
            }
          } else {
            if (cell.getRow().getData().style === "METRIC") {
               cell.getElement().style.maxHeight = "30px";
              cell.getElement().style.height = "30px";
              cell.getElement().style.paddingTop = "15px";
            }
          }
          return cell.getValue();
        }
      });
      // Hidden column
      headers.value.push({
        title: "Style",
        minWidth: 200,
        responsive: 0,
        field: "style",
        vertAlign: "middle",
        print: false,
        download: false,
        hozAlign: "left",
        visible: false,
        formatter: function(cell) {
          cell.getElement().style.maxHeight = "15px";
          if (cell.getRow().getData().style !== "METRIC") {
            cell.getElement().style.backgroundColor = "#E2EFDA";
          }
          return cell.getValue();
        }
      });

      // Columns coloring
      let index = 0;
      for (let item of data.heads) {
        let object = undefined;
        let otherObject = [];
        for (let subItem of item.columns) {
          let field = "values[" + index + "]";
          object = {
            title: subItem.name,
            width: 120,
            responsive: 0,
            field: field,
            vertAlign: "middle",
            print: false,
            download: false,
            hozAlign: "right",
            resizable: false,
            headerSort: false,
            formatter: function(cell) {
              let formatCell = true;
              let percentageRow = false;
               cell.getElement().style.maxHeight = "16px";
              cell.getElement().style.height = "16px";
              if (cell.getRow().getData().style === "METRIC") {
                cell.getElement().style.paddingTop = "10px"
                cell.getElement().style.maxHeight = "35px"
                cell.getElement().style.height = "35px"
                formatCell = false;
              } else {
                cell.getElement().style.marginTop = "-6px"
                cell.getElement().style.height = "30px"
                if (cell.getRow().getData().style === "GROUP") {
                  cell.getElement().style.maxHeight = "35px"
                  cell.getElement().style.height = "35px"
                  cell.getElement().style.paddingTop = "15px"
                  cell.getElement().style.marginTop = "0px"
                }
                percentageRow = cell.getRow().getData().style === "PERCENTAGE";
              }
              if (cell.getValue() === null || isNaN(cell.getValue())) {
                if (formatCell) {
                  if (
                    subItem.format === "PERCENTAGE" ||
                    subItem.format === "DIFF"
                  ) {
                    cell.getElement().style.fontStyle = "italic";
                    cell.getElement().style.backgroundColor = "#ECECEC";
                  } else {
                    cell.getElement().style.backgroundColor = "#FFFFFF";
                  }
                }
                return "n/a";
              }
              let number = cell.getValue();
              if (Object.is(number, -0)) {
                number = 0;
              }
              switch (subItem.format) {
                case "NUMBER":
                  if (formatCell) {
                    cell.getElement().style.backgroundColor = "#FFFFFF";
                  }
                  if (percentageRow) {
                    return reformatPercentage(number);
                  } else {
                    return new Intl.NumberFormat('sk-SK').format(Math.round(number));
                  }
                case "PERCENTAGE":
                  cell.getElement().style.fontStyle = "italic";
                  if (formatCell) {
                    cell.getElement().style.backgroundColor = "#ECECEC";
                  }
                  return reformatPercentage(number);
                case "DIFF":
                  cell.getElement().style.fontStyle = "italic";
                  if (formatCell) {
                    cell.getElement().style.backgroundColor = "#ECECEC";
                  }
                  if (percentageRow) {
                    return reformatPercentage(number);
                  } else {
                    return new Intl.NumberFormat('sk-SK').format(Math.round(number));
                  }
                default:
                  return number;
              }
            }
          };
          otherObject.push(object);
          index++;
        }
        headers.value.push({
          title: item.label === '' && index > 0 ? lastYearTable.value : item.label,
          columns: otherObject
        });
        lastYearTable.value = item.label;
      }
      initTabulator();
      reInitOnResizeWindow();
    };

    const clearFormData = () => {
      strediskoSelect.value = "";
      zakazkaSelect.value = "";
      gP1Select.value = "";
      gP2Select.value = "";
      gP3Select.value = "";
      const store = useStore();
      const y = store.getters["status/getYear"];
      selectedYear.value = y > optionsYears.value[0].key ? optionsYears.value[0].key : y
      selectedMonth.value = y > optionsYears.value[0].key ? 12 : store.getters["status/getMonth"];
      getData(1);
    };

    const getData = empty => {
      // const store = useStore();
      let request = getRequest;
      request.value.year = selectedYear.value;
      request.value.month = selectedMonth.value;
      if (empty === 1) {
        request.value.filter = {
          stredisko: null,
          zakazka: null,
          grupacnePole1: null,
          grupacnePole2: null,
          grupacnePole3: null
        }; //EmptyBody
      } else {
        request.value.filter = {
          stredisko:
            strediskoSelect.value === "" ? null : strediskoSelect.value,
          zakazka: zakazkaSelect.value === "" ? null : zakazkaSelect.value,
          grupacnePole1: gP1Select.value === "" ? null : gP1Select.value,
          grupacnePole2: gP2Select.value === "" ? null : gP2Select.value,
          grupacnePole3: gP3Select.value === "" ? null : gP3Select.value
        };
        request.value.year = selectedYear.value;
        request.value.month = selectedMonth.value;
      }
      axios.put(getApi() + `/report/suvaha`, request.value).then(response => {
        loadedData.value = response.data;
        updateTabulator(true, response.data);
      });
    };

    const onChangeFilter = () => {
      const store = useStore();
      store.commit({
        type: "status/save",
        year: selectedYear.value,
        month: selectedMonth.value
      });
      getData(0);
    };

    const onChangePreviousYear = () => {
      // TODO
    };

    const companyChanged = () => {
      const store = useStore();
      const y = store.getters["status/getYear"];
      selectedYear.value = y > optionsYears.value[0].key ? optionsYears.value[0].key : y
      selectedMonth.value = y > optionsYears.value[0].key ? 12 : store.getters["status/getMonth"];
      store.commit({
        type: "status/save",
        year: selectedYear.value,
        month: selectedMonth.value
      });
      getFilters();
      getData(1);
      curr.value = props.currency;
      multiply.value = props.multiply === "THOUSAND" ? "k" : props.multiply === "MILLION" ? "m" : ""
    }

    onBeforeMount(() => {
      companyChanged()
    });

    watch(props, () => {
      companyChanged()
    })


    const title = computed(() => {
      return props.companyName + " "
    })

    const titleExtend = computed(() => {
      return `<p> ${props.companyName} <br><br> Súvaha vývoj ${selectedYear.value}/${selectedMonth.value}</p>`
    })

    const reportTitle = computed(() => {
      return "Súvaha vývoj"
    })

    const subTitle = computed(() => {
      return "(v " + multiply.value + props.currency + ")"
    })

    const cancelFilter = () => {
      strediskoSelect.value = '';
      zakazkaSelect.value = '';
      gP1Select.value = '';
      gP2Select.value = '';
      gP3Select.value = '';
      const store = useStore();
      store.commit({
        type: "status/reset"
      });
      const y = store.getters["status/getYear"];
      selectedYear.value = y > optionsYears.value[0].key ? optionsYears.value[0].key : y
      selectedMonth.value = y > optionsYears.value[0].key ? 12 : store.getters["status/getMonth"];
      store.commit({
        type: "status/save",
        year: selectedYear.value,
        month: selectedMonth.value
      });
      getData(1);
    }

    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      downloadPDF,
      cancelFilter,
      downloadCSV,
      strediskoSelect,
      zakazkaSelect,
      gP1Select,
      gP2Select,
      gP3Select,
      optionStredisko,
      optionZakazka,
      optionGP1,
      optionGP2,
      optionGP3,
      selectedMonth,
      selectedYear,
      onChangeFilter,
      optionsYears,
      previousYear,
      onChangePreviousYear,
      clearFormData,
      forecast,
      downloadPivot,
      breadcrumb,
      showGP1,
      showGP2,
      showGP3,
      curr,
      isManager,
      title,
      subTitle,
      reportTitle
    };
  },
  computed: {
    pageTitle: function() {
      return this.companyName;
    }
  },
  watch: {
    companyIndex: "clearFormData"
  },
  created() {
  document.title = "Súvaha vývoj | Warren ";
}
});
</script>

<style scoped>
::v-deep .tabulator-col-resize-handle {
 cursor: default !important;
}
</style>
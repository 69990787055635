<template>
  <div>
    <div v-if="!loading" class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto">
            <img
              alt="Ilustračný obrázok"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <form
          class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0"
          @submit.prevent="save"
        >
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <div class="intro-x mb-12 flex justify-center">
              <img 
              alt="Aplikácia warren"
              src="@/assets/images/login-image.svg"
            />
            </div>
            <div class="intro-x mt-8">
              <input
                v-model.trim="validate.email.$model"
                type="email"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                :class="{ 'border-theme-6': validate.email.$error }"
                placeholder="Emailová adresa"
              />
              <template v-if="validate.email.$error">
                <div
                  v-for="(error, index) in validate.email.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
              <input
                v-model.trim="formData.password"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Heslo"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <a href="/reset-password">Zabudol som heslo</a>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                @click="save"
              >
                Prihlásiť sa
              </button>
            </div>
        </div>
        </form>
        <!-- END: Login Form -->
        <div
          id="failed-notification-content"
          class="toastify-content hidden flex"
        >
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Prihlásenie</div>
            <div class="text-gray-600 mt-1">
              Prosím skontrolujte formát svojej emailovej adresy
            </div>
          </div>
        </div>
        <div id="failed-login-content" class="toastify-content hidden flex">
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Chyba prihlásenia</div>
            <div class="text-gray-600 mt-1">
              Užívateľ so zadanou emailovou adresou neexistuje
            </div>
          </div>
        </div>
        <div id="failed-password-content" class="toastify-content hidden flex">
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Chyba prihlásenia</div>
            <div class="text-gray-600 mt-1">
              Zlé prihlasovacie údaje
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import cash from "cash-dom/dist/cash";
import router from "@/router";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import getApi, { getClientId, getClientSecret, getOauthUrlToken } from "@/main";
import OwnerVysledovka from "@/views/owner-vysledovka/Main";
import OwnerSuvaha from "@/views/owner-suvaha/Main";
import OwnerCashflow from "@/views/owner-cashflow/Main";
import Dashboard from "@/views/dashboard-overview/Main";
// import Dummy from "@/views/dummy/dummy";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import { getCurrentInstance } from "vue";
import CompanyVysledovka from "@/views/company-vysledovka/Main";
import CompanySuvaha from "@/views/company-suvaha/Main";
import CompanyVysledovkaVyvoj from "@/views/company-vysledovka-vyvoj/Main";
import CompanySuvahaVyvoj from "@/views/company-suvaha-vyvoj/Main";
import CompanyCashFlow from "@/views/company-cashflow/Main";
import NoCompany from "@/views/no-company/Main";
// import { useCookies } from '@vueuse/integrations/useCookies'

export default defineComponent({
  // components: {
  //   DarkModeSwitcher
  // },
  setup() {
    const route = useRoute();
    const loading = ref(true);
    const formData = reactive({
      email: "",
      password: "",
      name: ""
    });
    const rules = {
      email: {
        required,
        email
      }
    };
    const username = getClientId();
    const password = getClientSecret();
    const store = useStore();
    // const cookies = useCookies()
    const doLogin = ref(false);
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    const validate = useVuelidate(rules, toRefs(formData));
    const internalInstance = getCurrentInstance();
    const axios = internalInstance.appContext.config.globalProperties.axios;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${token}`
    };
    function clearFormData() {
      formData.email = "";
      formData.password = "";
    }
    function updateOwnerRouter(payload) {
      router.addRoute("root", {
        path: "dashboard",
        name: "side-menu-dash",
        component: Dashboard,
        meta: {
          requiredAuth: true
        },
        props: {
          userId: payload
        }
      });
      router.addRoute("root", {
        path: "ownerv",
        name: "side-menu-ownerv",
        component: OwnerVysledovka,
        meta: {
          requiredAuth: true
        },
        props: {
          userId: payload
        }
      });
      router.addRoute("root", {
        path: "owners",
        name: "side-menu-owners",
        component: OwnerSuvaha,
        meta: {
          requiredAuth: true
        },
        props: {
          userId: payload
        }
      });
      router.addRoute("root", {
        path: "ownerc",
        name: "side-menu-ownerc",
        component: OwnerCashflow,
        meta: {
          requiredAuth: true
        },
        props: {
          userId: payload
        }
      });
      // store.commit({type: "rtr/addRtr", rtr: router.getRoutes()})
    }
    function updateUserRouter(payload) {
      for (let item of payload) {
        router.addRoute("root", {
          path: "companyv/:id",
          name: "side-menu-companyv" + item.id,
          component: CompanyVysledovka,
          meta: {
            requiredAuth: true
          },
          props: {
            companyName: item.name,
            companyIndex: item.driveDirId,
            companyId: item.id,
            startMonth: item.startMonth,
            endMonth: item.endMonth,
            startYear: item.startYear,
            endYear: item.endYear,
            currency: item.currency,
            multiply: item.multiply
          }
        });
        router.addRoute("root", {
          path: "companyvv/:id",
          name: "side-menu-companyvv" + item.id,
          component: CompanyVysledovkaVyvoj,
          meta: {
            requiredAuth: true
          },
          props: {
            companyName: item.name,
            companyIndex: item.driveDirId,
            companyId: item.id,
            startMonth: item.startMonth,
            endMonth: item.endMonth,
            startYear: item.startYear,
            endYear: item.endYear,
            currency: item.currency,
            multiply: item.multiply
          }
        });
        router.addRoute("root", {
          path: "companys/:id",
          name: "side-menu-companys" + item.id,
          component: CompanySuvaha,
          meta: {
            requiredAuth: true
          },
          props: {
            companyName: item.name,
            companyIndex: item.driveDirId,
            companyId: item.id,
            startMonth: item.startMonth,
            endMonth: item.endMonth,
            startYear: item.startYear,
            endYear: item.endYear,
            currency: item.currency,
            multiply: item.multiply
          }
        });
        router.addRoute("root", {
          path: "companysv/:id",
          name: "side-menu-companysv" + item.id,
          component: CompanySuvahaVyvoj,
          meta: {
            requiredAuth: true
          },
          props: {
            companyName: item.name,
            companyIndex: item.driveDirId,
            companyId: item.id,
            startMonth: item.startMonth,
            endMonth: item.endMonth,
            startYear: item.startYear,
            endYear: item.endYear,
            currency: item.currency,
            multiply: item.multiply
          }
        });
        router.addRoute("root", {
          path: "companycf/:id",
          name: "side-menu-companycf" + item.id,
          component: CompanyCashFlow,
          meta: {
            requiredAuth: true
          },
          props: {
            companyName: item.name,
            companyIndex: item.driveDirId,
            companyId: item.id,
            startMonth: item.startMonth,
            endMonth: item.endMonth,
            startYear: item.startYear,
            endYear: item.endYear,
            currency: item.currency,
            multiply: item.multiply
          }
        });
      }
      // store.commit({type: "rtr/addRtr", rtr: router.getRoutes()})
    }
    function updateNoUserCompaniesRouter() {
      router.addRoute("root", {
          path: "nocompany",
          name: "side-menu-no",
          component: NoCompany,
          meta: {
            requiredAuth: true
          }
        });
    }
    function updateOwnerMenu() {
      store.commit({
        type: "sideMenu/addMenu",
        menu: {
          deleteOnLogout: true,
          icon: "TrelloIcon",
          pageName: "side-menu-dashboard",
          title: "Prehľad",
          subMenu: [
            {
              icon: null,
              pageName: "side-menu-dash",
              title: "Dashboard"
            },
            {
              icon: "FileMinusIcon",
              pageName: "side-menu-ownerv",
              title: "Výsledovka"
            },
            {
              icon: null,
              pageName: "side-menu-owners",
              title: "Súvaha"
            },
            {
              icon: null,
              pageName: "side-menu-ownerc",
              title: "Cashflow"
            }
          ]
        }
      });
      store.commit({
        type: "sideMenu/addMenu",
        menu: "devider"
      });
    }
    function updateUserCompanies(payload) {
      for (let item of payload) {
        store.commit({
          type: "sideMenu/addMenu",
          menu: {
            icon: "ClipboardIcon",
            pageName: "side-menu-pgr",
            title: item.name,
            active: false,
            deleteOnLogout: true,
            subMenu: [
              {
                icon: "FileMinusIcon",
                pageName: "side-menu-companyv" + item.id,
                title: "Výsledovka",
                id: item.id
              },
              {
                icon: "FileMinusIcon",
                pageName: "side-menu-companyvv" + item.id,
                title: "Výsledovka vyvoj",
                id: item.id
              },
              {
                icon: "FilePlusIcon",
                pageName: "side-menu-companys" + item.id,
                title: "Súvaha",
                id: item.id
              },
              {
                icon: "FilePlusIcon",
                pageName: "side-menu-companysv" + item.id,
                title: "Súvaha vývoj",
                id: item.id
              },
              {
                icon: "FileTextIcon",
                pageName: "side-menu-companycf" + item.id,
                title: "Cashflow",
                id: item.id
              }
            ]
          }
        });
      }
    }
    function updateNoUserCompaniesMenu() {
      store.commit({
          type: "sideMenu/addMenu",
          menu: {
            icon: "AlertCircleIcon",
            pageName: "side-menu-no",
            title: 'Bez firiem',
            active: false,
            deleteOnLogout: true
          }
        });
    }
    function wrongLogin() {
      Toastify({
        node: cash("#failed-password-content")
          .clone()
          .removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true
      }).showToast();
    }
    function wrongPassword() {
      Toastify({
        node: cash("#failed-password-content")
          .clone()
          .removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true
      }).showToast();
    }
    // function setToken(key: string, value: string, expiration: number) {
    //   // crate date object
    //   const date = new Date()
    //   // set expiration time +5 min from now
    //   const expirationTime = date.getTime() + expiration
    //   // update date object
    //   date.setTime(expirationTime)
    //   // set cookies
    //   cookies.set(key, value, { expires: date, path: '/' })
    // }
    function saveUser(id, role, token, userName) {
      store.commit({
        type: "user/loginUser",
        name: userName,
        email: formData.email,
        role: role,
        id: id,
        token: token
      });
    }
    function updateSideMenu() {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`
        }
      };
      axios
        .get(
          getApi() + "/company/byuser/" + store.getters["user/userId"],
          config
        )
        .then(response => {
          store.commit({type: "sideMenu/removeMenu"});
          if (response.data.length === 0) {
           updateNoUserCompaniesMenu();
           updateNoUserCompaniesRouter();
            router.push("/nocompany");
          } else {
            if (store.getters["user/isOwner"]) {
              updateOwnerMenu();
              updateUserCompanies(response.data);
              updateOwnerRouter(store.getters["user/userId"]);
              updateUserRouter(response.data);
              if(route.path === '/login') {
                router.push('/dashboard');
              } else {
                router.push(route.path);
              }
            } else {
              updateUserCompanies(response.data);
              updateUserRouter(response.data);
              router.push("/");
            }
          }
        });
    }
    function getData() {
      let qs = require("qs");
      return qs.stringify({
        client_id: getClientId(),
        client_secret: getClientSecret(),
        grant_type: "password",
        username: formData.email,
        password: formData.password
      });
    }
    function getToken(userData) {
      axios
        .post(getOauthUrlToken(), getData(), {
          headers: headers
        })
        .then(response => {
          saveUser(
            userData.id,
            userData.role,
            response.data.access_token,
            userData.firstName + " " + userData.lastName
          );
          updateSideMenu();
          doLogin.value = false;
        })
        .catch(() => {
          wrongPassword();
          clearFormData();
          doLogin.value = false;
          router.push("/login");
        });
    }

    function handleLogin() {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`
        }
      };
      if (formData.password.length > 0 && formData.email.length > 0) {
        doLogin.value = true;
        axios
          .put(
            getApi() + `/user/exist`,
            {
              login: formData.email,
              admin: false
            },
            config
          )
          .then(response => {
            if (response.data.id === null || response.data.id === 0) {
              wrongLogin();
              clearFormData();
              doLogin.value = false;
              router.push("/login");
            } else {
              getToken(response.data);
            }
          });
      }
    }

    function save() {
      if (doLogin.value === true) {
        return;
      } else {
        doLogin.value = true;
      }
      validate.value.$touch();
      if (validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      } else {
        handleLogin();
      }
    }

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
      loading.value = false;
    });

    const isLoggedUser = store.getters["user/isLoggedUser"];
    if (isLoggedUser) {
      updateSideMenu();
    }

    return {
      validate,
      formData,
      save,
      handleLogin,
      loading
    };
  }
});
</script>

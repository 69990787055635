const state = () => {
  return {
    rtr: [
    ]
  };
};

// getters
const getters = {
  rtr: state => state.rtr
};

// actions
const actions = {};

// mutations
const mutations = {
  addRtr(state, payload) {
    // localStorage.removeItem("rtr");
    state.rtr = [];
    state.rtr.push(payload.rtr);
    // localStorage.setItem("rtr", JSON.stringify(state.rtr));
  },
  removeRtr(state) {
    state.rtr = [];
    // localStorage.removeItem("rtr")
  },
  fromStorage(state) {
    // let test = JSON.parse(localStorage.getItem("rtr"));
    // if (test) {
    //   state.rtr = test;
    // } else {
      state.rtr = [];
    // }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

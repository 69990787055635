import { render } from "./Main.vue?vue&type=template&id=34b93d9e&scoped=true"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"

import "./Main.vue?vue&type=style&index=0&id=34b93d9e&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-34b93d9e"
/* custom blocks */
import block0 from "./Main.vue?vue&type=custom&index=0&blockType=route&lang=yaml"
if (typeof block0 === 'function') block0(script)


export default script
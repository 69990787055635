<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <!--          <a href="" class="-intro-x flex items-center pt-5">-->
          <!--            <img-->
          <!--              alt="Logo zákazníka"-->
          <!--              class="w-6"-->
          <!--              :src="require(`@/assets/images/logo.svg`)"-->
          <!--            />-->
          <!--            <span class="text-white text-lg ml-3">-->
          <!--              <span class="font-medium">Názov spoločnosti</span>-->
          <!--            </span>-->
          <!--          </a>-->
          <div class="my-auto">
            <img
              alt="Ilustračný obrázok"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="
                -intro-x
                text-white
                font-medium
                text-4xl
                leading-tight
                mt-10
              "
            >
              Obnova hesla
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <!--        <form class="validate-form" @submit.prevent="save">-->
        <form
          class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0"
          @submit.prevent="save"
        >
          <div
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
          <div class="intro-x mb-12 flex justify-center">
              <img 
              alt="Aplikácia warren"
              src="@/assets/images/login-image.svg"
            />
            </div>
            <!-- <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              "
            >
              Zadajte svoj email
            </h2> -->
            <div class="intro-x mt-8">
              <input
                id="validation-email"
                v-model.trim="state.email"
                name="email"
                type="email"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                "
                :class="{ 'border-theme-6': validate.email.$error }"
                placeholder="Zdajte emailovú adresu"
              />
              <template v-if="validate.email.$error">
                <div
                  v-for="(error, index) in validate.email.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="
                  btn btn-primary
                  py-3
                  px-4
                  w-full
                  xl:w-32
                  xl:mr-3
                  align-top
                "
                type="submit"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
        <!--        </form>-->
        <!-- END: Login Form -->
        <div
          id="failed-notification-content"
          class="toastify-content hidden flex"
        >
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Nepodarilo sa odoslať požiadavku</div>
            <div class="text-gray-600 mt-1">
              Prosím skontrolujte emailovú adresu
            </div>
          </div>
        </div>
        <div
          id="success-notification-content"
          class="toastify-content hidden flex"
        >
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
            <div class="font-medium">
              Požiadavka na resetovanie hesla bola poslaná
            </div>
            <div class="text-gray-600 mt-1">
              Prosím skontrolujte si svoju emailovú schránku
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs
} from "vue";
import cash from "cash-dom/dist/cash";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import router from "@/router";
import {
  getApplicationUrl,
  getClientId,
  getClientSecret,
  getOauthApi
} from "@/main";

export default defineComponent({
  setup() {
    const state = reactive({
      email: ""
    });
    const rules = {
      email: {
        required,
        email
      }
    };
    const validate = useVuelidate(rules, toRefs(state));
    const internalInstance = getCurrentInstance();
    const axios = internalInstance.appContext.config.globalProperties.axios;
    const username = getClientId();
    const password = getClientSecret();
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    const headers = {
      Authorization: `Basic ${token}`
    };
    const authDetails = computed(() => {
      return {
        client_id: getClientId(),
        login: state.email,
        redirect_uri: getApplicationUrl() + "/create-password"
      };
    });
    const save = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      } else {
        axios
          .post(getOauthApi() + "/oauth/password_reset", authDetails.value, {
            headers: headers
          })
          .then(() => {
            Toastify({
              node: cash("#success-notification-content")
                .clone()
                .removeClass("hidden")[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
            router.push("/login");
          })
          .catch(() => {
            Toastify({
              node: cash("#failed-change-password")
                .clone()
                .removeClass("hidden")[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
          });
      }
    };

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });

    return {
      validate,
      state,
      save
    };
  },
  data() {
    return {
      email: "",
      name: "",
      password: ""
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.validate.value.$touch();
      if (this.validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      } else {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      }
    }
  }
});
</script>

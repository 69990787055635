<route lang="yaml">
meta:
  requiredAuth: true
</route>

<template>
  <div>
    <TopBar
      title="Majiteľský cashflow"
      @download-pdf="downloadPDF"
      @download-csv="downloadCSV"
    >
    </TopBar>
<!--    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">-->
<!--      <h2 class="text-lg font-medium mr-auto">Majiteľský cashflow</h2>-->
<!--      <div class="flex mt-5 sm:mt-0">-->
<!--        <button-->
<!--          id="pdf-print"-->
<!--          class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"-->
<!--          type="button"-->
<!--          @click="downloadPDF()"-->
<!--        >-->
<!--          <BookOpenIcon class="w-4 h-4 mr-2" /> Stiahni PDF-->
<!--        </button>-->
<!--        <button-->
<!--          id="csv-print"-->
<!--          class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"-->
<!--          type="button"-->
<!--          @click="downloadCSV()"-->
<!--        >-->
<!--          <ListIcon class="w-4 h-4 mr-2" /> Stiahni CSV-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <form id="tabulator-html-filter-form" class="flex flex-col sm:flex-row sm:items-end xl:items-center">
          <div class="sm:flex items-center sm:mr-4">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Rok</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-year"
              v-model="selectedYear"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <option
                v-for="option in optionsYears"
                :key="option.key"
                :value="option.text"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
<!--            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"-->
<!--              >Mesiac</label-->
<!--            >-->
            <select
              id="tabulator-html-filter-month"
              v-model="selectedMonth"
              class="form-select w-full mt-2 sm:mt-0 sm:w-52"
              @change="onChangeFilter"
            >
              <option value="1" selected>Január</option>
              <option value="2">Február</option>
              <option value="3">Marec</option>
              <option value="4">Apríl</option>
              <option value="5">Máj</option>
              <option value="6">Jún</option>
              <option value="7">Júl</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">Október</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div
            v-if="!hideSegments"
            class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0"
          >
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Segment</label
            >
            <select
              id="tabulator-html-filter-segment"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              @change="onChangeFilter"
            >
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Môj podiel</label
            >
            <div class="mt-2 w-full sm:mt-0 sm:w-auto">
              <input
                v-model="myShare"
                type="checkbox"
                class="custom-control-input form-check-switch"
                @change="onChangeFilter"
              />
            </div>
          </div>
          <div class="sm:flex items-center sm:mr-4 xl:ml-auto mt-2 xl:mt-0">
            <button
                id="cancel-filter"
                class="ml-3 btn box  btn-outline-dark calncel-filter-button"
                type="button"
                @click="cancelFilter"
              >
                <RotateCcwIcon class="hidden sm:block w-4 h-4 mr-2" /> Zruš filter
              </button>
            </div>
        </form>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  reactive,
  ref
} from "vue";
import xlsx from "xlsx";
import feather from "feather-icons";
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import TopBar from "@/components/top-bar/Main";
import getApi from "@/main";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    TopBar
  },
  props: {
    userId: {
      type: Number,
      default: 0,
      required: false
    }
  },
  setup() {
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "name",
      type: "like",
      value: ""
    });
    const loadedDataStredisko = ref([]);
    const strediskoSelect = ref("");
    const loadedDataZakazka = ref([]);
    const zakazkaSelect = ref("");
    const loadedDataGP1 = ref([]);
    const gP1Select = ref("");
    const loadedDataGP2 = ref([]);
    const gP2Select = ref("");
    const loadedDataGP3 = ref([]);
    const gP3Select = ref("");
    const internalInstance = getCurrentInstance();
    const axios = internalInstance.appContext.config.globalProperties.axios;
    const datas = ref([]);
    const headers = ref([]);
    const previousYear = ref(true);
    const forecast = ref(true);
    const loadedData = ref();
    const selectedMonth = ref("12");
    const selectedYear = ref("2021");
    const companiesShare = ref([]);
    const companiesShareAll = ref([]);
    const myShare = ref(true);
    const userCompanyCount = ref(0);
    const isDesktopResolution = Boolean(window.innerWidth > 768);

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        selectable: false,
        footerElement: "<p></p>",
        maxHeight:"100%",
        columnHeaderVertAlign: "bottom",
        headerHozAlign: "right",
        placeholder: "Žiadny záznam",
        // Row formatter
        rowFormatter: function(row) {
          row.getElement().style.height = "15px";
          row.getElement().style.minHeight = "15px";
          row.getElement().style.fontFamily = "Arial, sans-serif";
          if (row.getData().style === "METRIC") {
            row.getElement().style.backgroundColor = "#B2E29C";
            row.getElement().style.fontWeight = "bold";
            row.getElement().style.fontSize = "16px";
            row.getElement().style.height = "40px";
          }
          if (row.getData().style === "PERCENTAGE") {
            row.getElement().style.fontStyle = "italic";
            row.getElement().style.fontWeight = "bold";
            row.getElement().style.fontSize = "12px";
          }
          if (row.getData().style === "GROUP") {
            row.getElement().style.fontWeight = "bold";
            row.getElement().style.fontSize = "16px";
            row.getElement().style.height = "30px";
          }
        },
        columns: headers.value,
        renderComplete() {
          feather.replace({
            "stroke-width": 1.5
          });
        },
        data: datas.value,
      });
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5
        });
      });
    };

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "name";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };

    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };

    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products"
      });
    };

    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true
      });
    };

    // Print
    const onPrint = () => {
      tabulator.value.print();
    };

    const getRequest = computed(() => {
      const data = {
        stredisko: strediskoSelect.value === "" ? null : strediskoSelect.value,
        zakazka: zakazkaSelect.value === "" ? null : zakazkaSelect.value,
        grupacnePole1: gP1Select.value === "" ? null : gP1Select.value,
        grupacnePole2: gP2Select.value === "" ? null : gP2Select.value,
        grupacnePole3: gP3Select.value === "" ? null : gP3Select.value
      };

      const visualData = {
        monthly: false,
        prev: previousYear.value,
        fcst: forecast.value
      };

      const shares = myShare.value ? companiesShare.value : companiesShareAll.value;

      return {
        companyId: null,
        year: selectedYear.value,
        month: selectedMonth.value,
        filter: data,
        visual: visualData,
        shares: shares
      };
    });

    const downloadPDF = () => {
      const store = useStore();
      axios
        .put(getApi() + `/report/cashflow/owner/pdf/`, getRequest.value,
          {
            responseType: 'blob',
            Authorization: `Bearer ${store.getters["user/token"]}`
          })
        .then(response => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "majitelský-cashflow.pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        });
    };

    const downloadCSV = () => {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`,
          responseType: "blob"
        }
      };
      axios
        .put(getApi() + `/report/cashflow/owner/csv/`, getRequest.value, config)
        .then(response => {
          const blob = new Blob([response.data], { type: "text/plain" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "majitelský-cashflow.csv";
          link.click();
          URL.revokeObjectURL(link.href);
        });
    };

    const optionStredisko = computed(() => {
      return loadedDataStredisko.value;
    });
    const optionZakazka = computed(() => {
      return loadedDataZakazka.value;
    });
    const optionGP1 = computed(() => {
      return loadedDataGP1.value;
    });
    const optionGP2 = computed(() => {
      return loadedDataGP2.value;
    });
    const optionGP3 = computed(() => {
      return loadedDataGP3.value;
    });

    const optionsYears = computed(() => {
      const yearArray = [];
      const firstYear = parseInt(2013);
      let year = parseInt(2021);
      while (firstYear < year) {
        yearArray.push({ key: year, text: String(year) });
        year--;
      }
      return yearArray;
    });

    const lastYearTable = ref();

    const updateTabulator = (previousYear, data) => {
      datas.value = [];
      for (let item of data.rows) {
        let name = item.name;
        if (item.style === "PERCENTAGE") {
          name = "<span style='padding-left:9px'>" + name;
        }
        if (item.style === "ITEM") {
          name = "<span style='padding-left:18px'>" + name;
        }
        let dataRow = {
          name: name,
          style: item.style
        };
        let index = 0;
        for (let subItem of item.values) {
          let field = "values[" + index + "]";
          dataRow[field] = subItem;
          index++;
        }
        datas.value.push(dataRow);
      }
      headers.value = [];
      // First column formatting
      headers.value.push({
        headerHozAlign: "left",
        title: `Majiteľský cashflow ${selectedYear.value}/${selectedMonth.value}`,
        width: 320,
        responsive: 0,
        field: "name",
        vertAlign: "middle",
        print: false,
        download: false,
        hozAlign: "left",
        frozen: isDesktopResolution,
        resizable: false,
        headerSort: false,
        formatter: function(cell) {
          cell.getElement().style.maxHeight = "16px";
          cell.getElement().style.height = "16px";
          if (cell.getRow().getData().style !== "METRIC") {
            cell.getElement().style.backgroundColor = "#E2EFDA";
            if (cell.getRow().getData().style === "GROUP") {
                cell.getElement().style.maxHeight = "30px";
                cell.getElement().style.height = "30px";
                cell.getElement().style.paddingTop = "15px";
            }
          } else {
            if (cell.getRow().getData().style === "METRIC") {
              cell.getElement().style.maxHeight = "30px";
              cell.getElement().style.height = "30px";
              cell.getElement().style.paddingTop = "15px";
            }
          }
          return cell.getValue();
        }
      });
      // Hidden column
      headers.value.push({
        title: "Style",
        minWidth: 200,
        responsive: 0,
        field: "style",
        vertAlign: "middle",
        print: false,
        download: false,
        hozAlign: "left",
        visible: false,
        formatter: function(cell) {
          cell.getElement().style.maxHeight = "15px";
          if (cell.getRow().getData().style !== "METRIC") {
            cell.getElement().style.backgroundColor = "#E2EFDA";
          }
          return cell.getValue();
        }
      });

      // Columns coloring
      let index = 0;
      const lastColumn = data.heads.length;
      for (let item of data.heads) {
        let object = undefined;
        let otherObject = [];
        for (let subItem of item.columns) {
          let field = "values[" + index + "]";
          object = {
            title: subItem.name,
            width: 120,
            responsive: 0,
            field: field,
            vertAlign: "middle",
            print: false,
            download: false,
            hozAlign: "right",
            resizable: false,
            headerSort: false,
            formatter: function(cell) {
              let formatCell = true;
              let percentageRow = false;
              cell.getElement().style.maxHeight = "16px";
              cell.getElement().style.height = "16px";
              if (cell.getRow().getData().style === "METRIC") {
                cell.getElement().style.paddingTop = "10px"
                cell.getElement().style.maxHeight = "35px"
                cell.getElement().style.height = "35px"
                formatCell = false;
              } else {
                cell.getElement().style.marginTop = "-6px"
                cell.getElement().style.height = "30px"
                if (cell.getRow().getData().style === "GROUP") {
                  cell.getElement().style.maxHeight = "35px"
                  cell.getElement().style.height = "35px"
                  cell.getElement().style.paddingTop = "15px"
                  cell.getElement().style.marginTop = "0px"
                }
                percentageRow = cell.getRow().getData().style === "PERCENTAGE";
              }
              if (cell.getValue() === null || isNaN(cell.getValue())) {
                if (formatCell) {
                  if (
                    subItem.format === "PERCENTAGE" ||
                    subItem.format === "DIFF"
                  ) {
                    cell.getElement().style.fontStyle = "italic";
                    cell.getElement().style.backgroundColor = "#ECECEC";
                  } else {
                    cell.getElement().style.backgroundColor = "#FFFFFF";
                  }
                }
                return "n/a";
              }
              let number = cell.getValue();
              if (Object.is(number, -0)) {
                number = 0;
              }
              switch (subItem.format) {
                case "NUMBER":
                  if (formatCell) {
                    cell.getElement().style.backgroundColor = "#FFFFFF";
                  }
                  if (percentageRow) {
                    return Math.round(number * 10) / 10 + "%";
                  } else {
                    return new Intl.NumberFormat('sk-SK').format(Math.round(number));
                  }
                case "PERCENTAGE":
                  cell.getElement().style.fontStyle = "italic";
                  if (formatCell) {
                    cell.getElement().style.backgroundColor = "#ECECEC";
                  }
                  return Math.round(number * 10) / 10 + "%";
                case "DIFF":
                  if (lastColumn !== index) {
                    cell.getElement().style.fontStyle = "italic";
                  }
                  if (formatCell) {
                    cell.getElement().style.backgroundColor = "#ECECEC";
                  }
                  if (percentageRow) {
                    return Math.round(number * 10) / 10 + "%";
                  } else {
                    return new Intl.NumberFormat('sk-SK').format(Math.round(number));
                  }
                default:
                  return number;
              }
            }
          };
          otherObject.push(object);
          index++;
        }
        headers.value.push({
          title: item.label === '' && index > 0 ? lastYearTable.value : item.label,
          columns: otherObject
        });
        lastYearTable.value = item.label;
      }
      initTabulator();
      reInitOnResizeWindow();
    };

    const clearFormData = () => {
      strediskoSelect.value = "";
      zakazkaSelect.value = "";
      gP1Select.value = "";
      gP2Select.value = "";
      gP3Select.value = "";
      const store = useStore();
      selectedYear.value = store.getters["status/getYear"];
      selectedMonth.value = store.getters["status/getMonth"];
      getData(1);
    };

    const getData = empty => {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`
        }
      };
      let request = getRequest;
      request.value.year = selectedYear.value;
      request.value.month = selectedMonth.value;
      if (empty === 1) {
        request.value.filter = {
          stredisko: null,
          zakazka: null,
          grupacnePole1: null,
          grupacnePole2: null,
          grupacnePole3: null
        }; //EmptyBody
      } else {
        request.value.filter = {
          stredisko:
            strediskoSelect.value === "" ? null : strediskoSelect.value,
          zakazka: zakazkaSelect.value === "" ? null : zakazkaSelect.value,
          grupacnePole1: gP1Select.value === "" ? null : gP1Select.value,
          grupacnePole2: gP2Select.value === "" ? null : gP2Select.value,
          grupacnePole3: gP3Select.value === "" ? null : gP3Select.value
        };
        request.value.year = selectedYear.value;
        request.value.month = selectedMonth.value;
      }
      axios
        .put(getApi() + `/report/cashflow/owner`, request.value, config)
        .then(response => {
          loadedData.value = response.data;
          updateTabulator(true, response.data);
        });
    };

    const onChangeFilter = () => {
      const store = useStore();
      store.commit({
        type: "status/save",
        year: selectedYear.value,
        month: selectedMonth.value
      });
      getData(0);
    };

    onBeforeMount(() => {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`
        }
      };
      const y = store.getters["status/getYear"];
      selectedYear.value = y > optionsYears.value[0].key ? optionsYears.value[0].key : y
      selectedMonth.value = y > optionsYears.value[0].key ? 12 : store.getters["status/getMonth"];
      store.commit({
        type: "status/save",
        year: selectedYear.value,
        month: selectedMonth.value
      });
      const user = store.getters["user/userId"];
      // getFilters();
      axios.get(getApi() + "/user/companies", config).then(response => {
        let userItems = response.data.find(u => u.userId === user);
        if (userItems) {
          for (let uitem of userItems.companies) {
            if (uitem.statusCsv !== "EMPTY") {
              companiesShare.value.push({
                companyId: uitem.id,
                share: uitem.share / 100
              });
            }
            companiesShareAll.value.push({
              companyId: uitem.id,
              share: 1
            });
          }
          userCompanyCount.value = userItems.companies.length;
        }
        getData(1);
      });
    });

    const hideSegments = computed(() => {
      return userCompanyCount.value < 3;
    });

    const cancelFilter = () => {
      myShare.value = true;
      const store = useStore();
      store.commit({
        type: "status/reset"
      });
      const y = store.getters["status/getYear"];
      selectedYear.value = y > optionsYears.value[0].key ? optionsYears.value[0].key : y
      selectedMonth.value = y > optionsYears.value[0].key ? 12 : store.getters["status/getMonth"];
      store.commit({
        type: "status/save",
        year: selectedYear.value,
        month: selectedMonth.value
      });
      getData(1);
    }

    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      downloadPDF,
      downloadCSV,
      strediskoSelect,
      zakazkaSelect,
      gP1Select,
      gP2Select,
      gP3Select,
      optionStredisko,
      optionZakazka,
      optionGP1,
      optionGP2,
      optionGP3,
      selectedMonth,
      selectedYear,
      onChangeFilter,
      optionsYears,
      previousYear,
      clearFormData,
      forecast,
      myShare,
      hideSegments,
      cancelFilter
    };
  },
  computed: {
    pageTitle: function() {
      return "Warren " + this.companyName;
    }
  },
  watch: {
    companyIndex: "clearFormData"
  },
  created() {
    document.title = "Majiteľský cashflow | Warren ";
  }
});
</script>

<style scoped>
::v-deep .tabulator-col-resize-handle {
 cursor: default !important;
}
</style>
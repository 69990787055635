<template>
  <Chart
    type="doughnut"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    share: {
      type: Number,
      default: 100
    }
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.main.darkMode);
    const data = computed(() => {
      return {
        labels: ["Môj podiel", ""],
        datasets: [
          {
            data: [props.share, 100 - props.share],
            backgroundColor: ["#FF8B26", "#FFFFFF", "#285FD3"],
            hoverBackgroundColor: ["#FF8B26", "#FFFFFF", "#285FD3"],
            borderWidth: 5,
            borderColor: darkMode.value ? "#303953" : "#fff"
          }
        ]
      };
    });
    const options = computed(() => {
      return {
        legend: {
          display: false
        },
        cutoutPercentage: 80
      };
    });

    return {
      data,
      options
    };
  }
});
</script>

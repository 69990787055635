import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";
import Login from "../views/login/Main.vue";
import Register from "../views/register/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";
import ErrorPasswordPage from "../views/error-reset-password-page/Main.vue";
import Dummy from "../views/dummy/dummy";
import ForgotPassword from "../views/forgot-password/Main";
import NewPassword from "../views/new-password/Main";
import { useStore } from "@/store";
import axios from 'axios'

import {
  getOauthApi,
  getClientId,
  getClientSecret,
} from "@/main";

let routes = [
  {
    path: "/",
    component: SideMenu,
    name: "root",
    children: [
      {
        path: "/",
        name: "dummy",
        component: Dummy,
        meta: {
          requiredAuth: true
        }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: "/error-reset-password-page",
    name: "error-reset-password-page",
    component: ErrorPasswordPage,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: "/:pathMatch(.*)*",
    component: Login,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: "/reset-password",
    component: ForgotPassword,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: "/create-password",
    component: NewPassword,
    beforeEnter: checkLink,
    meta: {
      requiredAuth: false
    }
  }
];

function checkLink(to,from,next) {
  if (to.query.code) {
    try {
       axios.post(getOauthApi() + "/oauth/check_password_setup", {client_id: username, code: to.query.code} , {
              headers: headers
            }).then((response) => {
              if(!response.data) {
                 next({ name: "error-reset-password-page" });
              } else {
                next();
              }
        });
        // eslint-disable-next-line no-empty
        } catch (error) { 
        }
   
  } else {
     next();
  }
}


const username = getClientId();
const password = getClientSecret();
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
const headers = {
      Authorization: `Basic ${token}`
    };
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

// Check the user's status, and route rules
router.beforeEach((to, from, next) => {
  const requiredAuth = to.matched.some(route => route.meta.requiredAuth);
  // console.log(requiredAuth)
  const store = useStore();
  store.commit({
    type: "user/checkSessionState"
  });
  const isLoggedUser = store.getters["user/isLoggedUser"];
  // console.log(isLoggedUser)
  // console.log(requiredAuth)
  // console.log(to)
  if (!isLoggedUser && requiredAuth) {
    // console.log('to login')
    next({ name: "login" });
  } else {
    if (isLoggedUser) {
      store.commit({ type: "sideMenu/fromStorage" });
      // store.commit({ type: "rtr/fromStorage" });
    }
    // const rts = store.getters["rtr/rtr"]
    // for(let item of rts) {
    //   for(let path of item) {
    //     // console.log(path)
    //     router.addRoute(path);
    //   }
    // }
    // console.log(to)
    next();
  }
});

export default router;

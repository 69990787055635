const state = () => {
  return {
    status: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    }
  };
};

const getters = {
  getYear: state => {
    return state.status.year;
  },
  getMonth: state => {
    return state.status.month;
  }
};

const mutations = {
  save(state, payload) {
    state.status.year = payload.year;
    state.status.month = payload.month;
  },
  reset(state) {
    state.status.year = new Date().getFullYear()
    state.status.month = new Date().getMonth() + 1
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};

<template>
  <div>
    <div class="container sm:px-10">
     <div class="block xl:grid grid-cols-2 gap-4">
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto">
            <img
              alt="Ilustračný obrázok"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
        </div>
        </div>
        <form
          class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0"
          @submit.prevent="save"
        >
          <div
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              "
            >
              Vytvorenie nového hesla
            </h2>
            <div class="intro-x mt-8">
              <input
                id="validation-password"
                v-model.trim="state.heslo.password"
                name="password"
                type="password"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                "
                placeholder="Heslo"
              />
            </div>
            <label
              class="
                intro-x
                font-bold
                text-xs
                text-center
                xs:text-left
              "
            >
              Nové heslo
            </label>
            <template v-if="validate.heslo.password.$error">
              <div
                v-for="(error, index) in validate.heslo.password.$errors"
                :key="index"
                class="text-theme-6 mt-2"
              >
                {{ error.$message }}
              </div>
            </template>
            <div class="intro-x mt-8">
              <input
                id="validation-password-check"
                v-model.trim="state.heslo.confirm"
                name="password"
                type="password"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                "
                placeholder="Heslo"
              />
            </div>
            <label
              class="
                intro-x
                font-bold
                text-xs
                text-center
                xs:text-left
              "
            >
              Nové heslo pre kontrolu ešte raz
            </label>
            <template v-if="validate.heslo.confirm.$error">
              <div
                v-for="(error, index) in validate.heslo.confirm.$errors"
                :key="index"
                class="text-theme-6 mt-2"
              >
                {{ error.$message }}
              </div>
            </template>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="
                  btn btn-primary
                  py-3
                  px-4
                  w-full
                  xl:w-32
                  xl:mr-3
                  align-top
                "
                type="submit"
              >
                Nastav
              </button>
            </div>
          </div>
        </form>
        <div
          id="failed-notification-content"
          class="toastify-content hidden flex"
        >
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Nepodarilo sa odoslať požiadavku</div>
            <div class="text-gray-600 mt-1">
              Prosím vyplňte všetky požadované polia
            </div>
          </div>
        </div>
        <div
          id="success-notification-content"
          class="toastify-content hidden flex"
        >
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
            <div class="font-medium">
              Vytvorenie hesla
            </div>
            <div class="text-gray-600 mt-1">
              Heslo bolo úspešne zmenené
            </div>
          </div>
        </div>
        <div id="failed-change-password" class="toastify-content hidden flex">
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Chyba</div>
            <div class="text-gray-600 mt-1">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  toRefs
} from "vue";
import cash from "cash-dom/dist/cash";
import Toastify from "toastify-js";
import router from "@/router";
import { minLength, required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  getApplicationUrl,
  getClientId,
  getClientSecret,
  getOauthApi
} from "@/main";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const state = reactive({
      heslo: {
        password: "",
        confirm: ""
      }
    });
    const rules = computed(() => {
      return {
        heslo: {
          password: { required, minLength: minLength(6) },
          confirm: { required, sameAs: sameAs(state.heslo.password) }
        }
      };
    });
    const validate = useVuelidate(rules, toRefs(state));
    const internalInstance = getCurrentInstance();
    const axios = internalInstance.appContext.config.globalProperties.axios;
    const username = getClientId();
    const password = getClientSecret();
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    const headers = {
      Authorization: `Basic ${token}`
    };
    const route = useRoute();
    const authDetails = computed(() => {
      return {
        client_id: getClientId(),
        code: route.query.code,
        password: state.heslo.password,
        redirect_uri: getApplicationUrl() + "/login"
      };
    });
    const message = ref("");
    const save = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      } else {
        axios
          .post(getOauthApi() + "/oauth/password_setup", route.query.code ? authDetails.value : {
        client_id: getClientId(),
        user: route.query.user,
        password: state.heslo.password,
        redirect_uri: getApplicationUrl() + "/login"
      }, {
            headers: headers
          })
          .then(response => {
            message.value = response.message;
            Toastify({
              node: cash("#success-notification-content")
                .clone()
                .removeClass("hidden")[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
            router.push("/login");
          })
          .catch(response => {
            message.value = response.message;
            Toastify({
              node: cash("#failed-change-password")
                .clone()
                .removeClass("hidden")[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
          });
      }
    };
    const getActionType = computed(() => {
      if (route.query.type === "finish_user_registration") {
        return "nový užívateľ";
      } else {
        if (route.query.type === "update_user_password") {
          return "zmena hesla";
        }
      }
      return "";
    });
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
      if (!route.query.type) {
        router.push("/login");
      }
    });

    return {
      state,
      validate,
      save,
      getActionType
    };
  },
  data() {
    return {
      passwordMatch: "",
      password: ""
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.validate.value.$touch();
      if (this.validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      } else {
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      }
    }
  }
});
</script>

<route lang="yaml">
meta:
  requiredAuth: true
</route>

<template>
  <div>
    <TopBar :second-crumbs-item="breadcrumb"></TopBar>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Kontaktujte administrátora</h2>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref
} from "vue";
import TopBar from "@/components/top-bar/Main";

export default defineComponent({
  components: {
    TopBar
  },
  props: {
    companyName: {
      type: String,
      default: "",
      required: true
    },
    companyIndex: {
      type: String,
      default: "",
      required: true
    },
    companyId: {
      type: Number,
      default: 0,
      required: true
    },
    startMonth: {
      type: Number,
      default: 1,
      required: true
    },
    endMonth: {
      type: Number,
      default: 12,
      required: true
    },
    startYear: {
      type: Number,
      default: 2021,
      required: true
    },
    endYear: {
      type: Number,
      default: 2021,
      required: true
    },
    currency: {
      type: String,
      default: "",
      required: true
    }
  },
  setup() {
    const breadcrumb = ref('Bez priradenej firmy');

    return {
      breadcrumb,
    };
  }
});
</script>

<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar pb-8 justify-end">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-end pt-0">
        <form
          v-if="!dashboard"
          id="tabulator-html-filter-form2"
          class="xl:flex sm:mr-5 float-right"
        >
          <div class="flex items-center">
            <button
              id="pdf-print"
              class="ml-3 btn box   btn-outline-dark"
              type="button"
              @click="$emit('download-pdf')"
            >
              <BookOpenIcon class="hidden sm:block w-4 h-4 mr-2" /> Stiahni PDF
            </button>
            <button
              id="csv-print"
              class="ml-3 btn box   btn-outline-dark"
              type="button"
              @click="$emit('download-csv')"
            >
              <ListIcon class="hidden sm:block w-4 h-4 mr-2" /> Stiahni CSV
            </button>
            <button
              v-if="!isManager"
              id="xls-print"
              class="ml-3 btn box   btn-outline-dark"
              type="button"
              @click="$emit('download-xls')"
            >
              <ServerIcon class="hidden sm:block w-4 h-4 mr-2" /> Stiahni Pivot
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- BEGIN: Account Menu -->
    <div ref="account" class="intro-x dropdown w-8 h-8 mt-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="true"
        style="cursor: pointer"
      >
        <img
          alt="Warren"
          :src="require(`@/assets/images/account_avatar.png`)"
        />
      </div>
      <div v-if="!hideMe" class="dropdown-menu w-56">
        <div
          class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
        >
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">{{ user.name }}</div>
          </div>
          <div class="p-2">
            <a
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              style="cursor: pointer"
              @click="handleResetPassword()"
            >
              <LockIcon class="w-4 h-4 mr-2" /> Zmena hesla
            </a>
            <!--            <a-->
            <!--              href=""-->
            <!--              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
            <!--            >-->
            <!--              <HelpCircleIcon class="w-4 h-4 mr-2" /> Help-->
            <!--            </a>-->
          </div>
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <a
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              style="cursor: pointer"
              @click="handleLogout()"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Odhlásenie
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "@/store";
import router from "@/router";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TopBar",
  props: {
    secondCrumbsItem: {
      type: String,
      default: "",
      required: false
    },
    title: {
      type: String,
      default: "",
      required: false
    },
    subTitle: {
      type: String,
      default: "",
      required: false
    },
    isManager: {
      type: Boolean,
      default: true,
      required: false
    },
    reportTitle: {
      type: String,
      default: "",
      required: false
    },
    dashboard: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['download-pdf', 'download-csv', 'download-xls'],
  setup(props) {
    const searchDropdown = ref(false);
    const router = useRouter();

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const reportName = computed(() => {
      return props.dashboard ? "" : props.reportTitle !== "" ? " / " + props.reportTitle : ""
    })

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      router,
      reportName
    };
  },
  data() {
    return {
      user: {},
      hideMe: false
    };
  },
  computed: {
    secondCrumb: function() {
      return this.secondCrumbsItem;
    }
  },
  mounted() {
    this.refreshUser();
  },
  methods: {
    handleLogout() {
      this.hideMe = true;
      const store = useStore();
      store.commit({
        type: "user/logoutUser"
      });
      store.commit({ type: "sideMenu/removeMenu" });
      router.push({ name: "login" });
    },
    refreshUser() {
      const store = useStore();
      this.user = store.getters["user/user"];
      if (this.user.name === "") {
        this.user.name = "Žiadne meno";
      }
    },
    handleResetPassword() {
      this.hideMe = true;
      const store = useStore();
      const user = store.getters["user/user"];
      // store.commit({
      //   type: "user/logoutUser"
      // });
      // store.commit({ type: "sideMenu/removeMenu" });
      router.push(
        `/create-password?type=update_user_password&user=${user.email}`
      );
    }
  }
});
</script>

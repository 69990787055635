import { render } from "./Main.vue?vue&type=template&id=7a4b7d00&scoped=true"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"

import "./Main.vue?vue&type=style&index=0&id=7a4b7d00&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-7a4b7d00"
/* custom blocks */
import block0 from "./Main.vue?vue&type=custom&index=0&blockType=route&lang=yaml"
if (typeof block0 === 'function') block0(script)


export default script
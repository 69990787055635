const state = () => {
  return {
    menu: [
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {
  addMenu(state, payload) {
    state.menu.push(payload.menu);
    localStorage.setItem("menu", JSON.stringify(state.menu));
  },
  removeMenu(state) {
    state.menu = [];
    localStorage.removeItem("menu")
  },
  fromStorage(state) {
    let test = JSON.parse(localStorage.getItem("menu"));
    if (test) {
      state.menu = test;
    } else {
      state.menu = []
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

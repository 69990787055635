const state = () => {
  return {
    loggedUser: {
      email: "",
      name: "",
      role: "",
      token: "",
      id: "",
      refresh_token: ""
    }
  };
};

const getters = {
  isLoggedUser: state => {
    return (state.loggedUser) && (state.loggedUser.role !== "");
  },
  user: state => {
    return state.loggedUser;
  },
  isOwner: state => {
    return state.loggedUser.role === "OWNER";
  },
  isManager: state => {
    return state.loggedUser.role === "MANAGER";
  },
  userId: state => {
    return state.loggedUser.id;
  },
  token: state => {
    return state.loggedUser.token;
  },
  refreshToken: state => {
    return state.loggedUser.refresh_token;
  }
};

const mutations = {
  loginUser(state, payload) {
    state.loggedUser.email = payload.email;
    state.loggedUser.role = payload.role;
    state.loggedUser.name = payload.name;
    state.loggedUser.id = payload.id;
    state.loggedUser.refresh_token = payload.refresh_token;
    state.loggedUser.token = payload.token;
    sessionStorage.setItem("user", JSON.stringify(state.loggedUser));
  },
  logoutUser(state) {
    state.loggedUser = {
      email: "",
      name: "",
      role: "",
      token: "",
      id: "",
      refresh_token: ""
    };
    sessionStorage.removeItem("user");
  },
  checkSessionState(state) {
    let test = JSON.parse(sessionStorage.getItem("user"));
    if (test) {
      state.loggedUser = test;
    } else {
      state.loggedUser = {
        email: "",
        name: "",
        role: "",
        token: "",
        id: "",
        refresh_token: ""
      };
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};

import { createStore } from "vuex";
import main from "./main";
import sideMenu from "./side-menu";
import simpleMenu from "./simple-menu";
import topMenu from "./top-menu";
import user from "./user";
import status from "./stav";
import rtr from "./router-menu";

const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    user,
    status,
    rtr
  }
});

export function useStore() {
  return store;
}

export default store;

<route lang="yaml">
meta:
  requiredAuth: true
</route>

<template>
  <div>
    <TopBar
      title="Majiteľský dashboard"
      :dashboard="true"
    >
    </TopBar>
<!--    <TopBar second-crumbs-item=""></TopBar>-->
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 xxl:col-span-12">
        <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
<!--          <div class="intro-y flex items-center h-10">-->
<!--            <h2 class="text-lg font-medium truncate mr-5">-->
<!--              Majiteľský dashboard-->
<!--            </h2>-->
<!--            <a-->
<!--              href=""-->
<!--              class="ml-auto flex items-center text-theme-1 dark:text-theme-10"-->
<!--            >-->
<!--              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Načítaj dáta-->
<!--            </a>-->
<!--          </div>-->
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <p style="padding-top: 15px"></p>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Predchádzajúci rok</label
            >
            <div class="mt-2 w-full sm:mt-0 sm:w-auto">
              <input
                v-model="previousYear"
                type="checkbox"
                class="custom-control-input form-check-switch"
                @change="onChangeFilter"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
              <div class="report-box">
                <div class="box p-5">
                  <div class="flex">
                    <ShoppingCartIcon class="report-box__icon text-theme-10" />
                    <!-- <div class="ml-auto">
                      <Tippy
                        v-if="previousYear"
                        tag="div"
                        class="report-box__indicator bg-theme-9 cursor-pointer"
                        content="33% viac oproti minulému roku"
                      >
                        33% <ChevronUpIcon class="w-4 h-4" />
                      </Tippy>
                    </div> -->
                  </div>
                  <div class="text-xl font-bold leading-8 mt-6">
                    {{
                      new Intl.NumberFormat("sk-SK", {
                        style: "currency",
                        currency: "EUR"
                      }).format(number1)
                    }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">Tržby</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
              <div class="report-box">
                <div class="box p-5">
                  <div class="flex">
                    <CreditCardIcon class="report-box__icon text-theme-11" />
                    <!-- <div class="ml-auto">
                      <Tippy
                        v-if="previousYear"
                        tag="div"
                        class="report-box__indicator bg-theme-6 cursor-pointer"
                        content="2% menej oproti minulému roku"
                      >
                        2% <ChevronDownIcon class="w-4 h-4" />
                      </Tippy>
                    </div> -->
                  </div>
                  <div class="text-xl font-bold leading-8 mt-6">
                    {{
                      new Intl.NumberFormat("sk-SK", {
                        style: "currency",
                        currency: "EUR"
                      }).format(number2)
                    }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">EBITDA</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
              <div class="report-box">
                <div class="box p-5">
                  <div class="flex">
                    <MonitorIcon class="report-box__icon text-theme-12" />
                    <!-- <div class="ml-auto">
                      <Tippy
                        v-if="previousYear"
                        tag="div"
                        class="report-box__indicator bg-theme-9 cursor-pointer"
                        content="12% viac oproti minulému roku"
                      >
                        12% <ChevronUpIcon class="w-4 h-4" />
                      </Tippy>
                    </div> -->
                  </div>
                  <div class="text-xl font-bold leading-8 mt-6">
                    {{
                      new Intl.NumberFormat("sk-SK", {
                        style: "currency",
                        currency: "EUR"
                      }).format(number3)
                    }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">EBIT</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
              <div class="report-box">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon class="report-box__icon text-theme-9" />
                    <!-- <div class="ml-auto">
                      <Tippy
                        v-if="previousYear"
                        tag="div"
                        class="report-box__indicator bg-theme-9 cursor-pointer"
                        content="22% viac oproti minulému roku"
                      >
                        22% <ChevronUpIcon class="w-4 h-4" />
                      </Tippy>
                    </div> -->
                  </div>
                  <div class="text-xl font-bold leading-8 mt-6">
                    {{
                      new Intl.NumberFormat("sk-SK", {
                        style: "currency",
                        currency: "EUR"
                      }).format(number4)
                    }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">EBT</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
              <div class="report-box">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon class="report-box__icon text-theme-9" />
                    <!-- <div class="ml-auto">
                      <Tippy
                        v-if="previousYear"
                        tag="div"
                        class="report-box__indicator bg-theme-9 cursor-pointer"
                        content="22% viac oproti minulému roku"
                      >
                        22% <ChevronUpIcon class="w-4 h-4" />
                      </Tippy>
                    </div> -->
                  </div>
                  <div class="text-xl font-bold leading-8 mt-6">
                    {{
                      new Intl.NumberFormat("sk-SK", {
                        style: "currency",
                        currency: "EUR"
                      }).format(number5)
                    }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">EAT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->

          <div v-for="(item,index) in companiesInfo" :key="index" class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                {{ item.companyName }}
              </h2>
              <p v-if="item.status === 'WARNING'" class="ml-auto text-theme-6 font-bold dark:text-theme-10 truncate"
                >{{ item.status }}</p
              >
<!--              <p v-if="item.status === 'EMPTY'" class="ml-auto text-theme-11 dark:text-theme-10 truncate"-->
<!--              >{{ item.status }}</p-->
<!--              >-->
            </div>
            <div class="intro-y box p-5 mt-5">
              <ReportDonutChart :height="100" class="mt-3" :share="item.share"/>
              <div class="mt-8">
                <div class="flex items-center">
                  <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                  <span class="truncate">Môj podiel</span>
                  <div
                    class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                  ></div>
                  <span class="font-medium xl:ml-auto">{{ item.share }} %</span>
                </div>
                <div class="flex items-center mt-4">
                  <div class="w-2 h-2 bg-theme-1 rounded-full mr-3"></div>
                  <span class="truncate">Naposledy aktualizované</span>
                  <div
                    class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                  ></div>
                  <span v-if="item.status !== 'EMPTY'" class="font-medium xl:ml-auto">{{ new Date(Date.parse(item.statusDate)).toLocaleDateString('sk-SK') }}</span>
                  <span v-if="item.status === 'EMPTY'" class="font-medium xl:ml-auto">Dáta neboli ešte spracované</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  getCurrentInstance
} from "vue";
import getApi from "@/main";
import { useStore } from "@/store";
import TopBar from "@/components/top-bar/Main";
import ReportDonutChart from "../../components/report-donut-chart/Main";

export default defineComponent({
  components: {
    TopBar,
    ReportDonutChart
  },
  props: {
    userId: {
      type: Number,
      default: 0,
      required: false
    }
  },
  setup() {
    const number1 = ref(0);
    const number2 = ref(0);
    const number3 = ref(0);
    const number4 = ref(0);
    const number5 = ref(0);
    const number6 = ref(0);
    const salesReportFilter = ref();
    const internalInstance = getCurrentInstance();
    const axios = internalInstance.appContext.config.globalProperties.axios;
    const companiesShare = ref([]);
    const companiesInfo = ref([]);
    const selectedMonth = ref("12");
    const selectedYear = ref("2021");
    const previousYear = ref(false);

    onMounted(() => {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`
        }
      };
      const user = store.getters["user/userId"];
      store.commit({
        type: "status/reset"
      });
      selectedYear.value = store.getters["status/getYear"];
      selectedMonth.value = store.getters["status/getMonth"];
      axios.get(getApi() + "/user/companies", config).then(response => {
        let userItems = response.data.find(u => u.userId === user);
        if (userItems) {
          for (let company of userItems.companies) {
            if (company.statusCsv !== "EMPTY") {
              companiesShare.value.push({
                companyId: company.id,
                share: company.share
              });
            }
            companiesInfo.value.push({
              companyId: company.id,
              companyName: company.name,
              statusDate: company.statusedCsvOn,
              status: company.statusCsv,
              share: company.share
            });

          }
        }
        getData(1);
      });
    });

    const getRequest = computed(() => {
      const data = {
        stredisko: null,
        zakazka: null,
        grupacnePole1: null,
        grupacnePole2: null,
        grupacnePole3: null
      };

      const visualData = {
        monthly: false,
        prev: false,
        fcst: false
      };

      const shares = companiesShare.value;

      return {
        companyId: null,
        year: selectedYear.value,
        month: selectedMonth.value,
        filter: data,
        visual: visualData,
        shares: shares
      };
    });

    const getData = empty => {
      const store = useStore();
      const config = {
        headers: {
          Authorization: `Bearer ${store.getters["user/token"]}`
        }
      };
      let request = getRequest;
      request.value.year = selectedYear.value;
      request.value.month = selectedMonth.value;
      if (empty === 1) {
        request.value.filter = {
          stredisko: null,
          zakazka: null,
          grupacnePole1: null,
          grupacnePole2: null,
          grupacnePole3: null
        }; //EmptyBody
      } else {
        request.value.filter = {
          stredisko: null,
          zakazka: null,
          grupacnePole1: null,
          grupacnePole2: null,
          grupacnePole3: null
        };
        request.value.year = selectedYear.value;
        request.value.month = selectedMonth.value;
      }
      axios
        .put(getApi() + `/report/vysledovka/owner`, request.value, config)
        .then(response => {
          // console.log(response.data)
          if (response.data.rows.length > 0) {
            number1.value = response.data.rows.find(
              o => o.name === "Trzby"
            ).values[0];
            number2.value = response.data.rows.find(
              o => o.name === "EBITDA"
            ).values[0];
            number3.value = response.data.rows.find(
              o => o.name === "EBIT"
            ).values[0];
            number4.value = response.data.rows.find(
              o => o.name === "EBT"
            ).values[0];
            number5.value = response.data.rows.find(
              o => o.name === "EAT"
            ).values[0];
          }
        });
    };

    const onChangeFilter = () => {
      const store = useStore();
      store.commit({
        type: "status/reset"
      });
      if (previousYear.value) {
        selectedYear.value = store.getters["status/getYear"] - 1;
        selectedMonth.value = 12;
      } else {
        selectedYear.value = store.getters["status/getYear"];
        selectedYear.value = store.getters["status/getYear"];
      }
      getData(1);
    }

    return {
      salesReportFilter,
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      getRequest,
      companiesInfo,
      previousYear,
      onChangeFilter
    };
  },
  created() {
    document.title = "Majiteľský dashboard | Warren ";
  }
});
</script>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";
import axios from "axios";

// SASS Theme
import "./assets/sass/app.scss";

function developerMode() {
  return window.location.href.indexOf("localhost") !== -1;
}

function getEnv(name) {
  return window?.config?.[name] || process.env[name];
}

export default function getApi() {
  if (developerMode()) {
    return "https://api-warren.scrtest.sk";
  } else {
    return getEnv("API_URL");
  }
}

export function getApplicationUrl() {
  if (developerMode()) {
    return "https://warren.scrtest.sk";
  } else {
    return getEnv("APP_URL");
  }
}

export function getOauthApi() {
  if (developerMode()) {
    return "https://api-warren.scrtest.sk";
  } else {
    return getEnv("OAUTH2_URL");
  }
}

export function getClientId() {
  if (developerMode()) {
    return "warren_app";
  } else {
    return getEnv("CLIENT_ID");
  }
}

// QWEasd5sd2#132135DSAadas@45sASDa31 <- password
// $2y$12$0glgH7BjhfTDajFJEtFEp.Iu/4ImQYZsua8UD0qDagUw8WmUi65N6 <- hash 12
export function getClientSecret() {
  if (developerMode()) {
    // hash 12 rounds
    return "QWEasd5sd2#132135DSAadas@45sASDa31";
  } else {
    return getEnv("CLIENT_SECRET");
  }
}

export function getOauthUserTokenData(name, password) {
  return {
    client_id: getClientId(),
    client_secret: getClientSecret(),
    grant_type: "password",
    username: name,
    password: password,
    scope: "API"
  };
}

export function getOauthUrlToken() {
  return getOauthApi() + "/oauth/token";
}

export const reformatPercentage = (n) => {
  let result = "0,0%"
  if (n !== 0) {
    let str = (Math.round(n * 10) / 10).toString();
    if (str.indexOf('.') === -1) {
      return str + ',0%'
    } else {
      return str.replaceAll('.', ',') + '%'
    }
  }
  return result;
}

const app = createApp(App)
  .use(store)
  .use(router);

globalComponents(app);
utils(app);

app.config.globalProperties.axios = axios;

app.mount("#app");
